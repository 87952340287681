import React, { useState } from "react";
import { graphql } from 'gatsby';
//import gql from "graphql-tag";
import styled from "styled-components";
import { useMutation,gql } from "@apollo/client";
import SEO from "../components/seo";
import { useForm } from "react-hook-form";

//import Layout from '../components/layout'
import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Input,
	Textarea,
} from "@chakra-ui/react";
import Nav from "../components/Nav";

const CONTACT_MUTATION = gql`
	mutation CreateSubmissionMutation(
		$clientMutationId: String!
		$firstName: String!
		$lastName: String!
		$email: String!
		$message: String!
	) {
		createSubmission(
			input: {
				clientMutationId: $clientMutationId
				firstName: $firstName
				lastName: $lastName
				email: $email
				message: $message
			}
		) {
			success
			data
		}
	}
`;

const ParaGraph = styled.div`
	margin-top: 50px;
	p {
		font-family: "fieldwork";
		font-weight: 300;
		font-style: normal;
		font-size: 1rem;
		line-height: 1.4;
		color: #1a1818;
		padding-bottom: 1rem;
	}
	strong {
		font-weight: 700;
	}
`;

const ContactPage = ({ data: { page } }) => {
	const [firstNameValue, setFirstNameValue] = useState("");
	const [lastNameValue, setLastNameValue] = useState("");
	const [emailValue, setEmailValue] = useState("");
	const [messageValue, setMessageValue] = useState("");

	const [submitError,setSubmitError] = useState(false);
	const [submitSuccess,setSubmitSuccess] = useState(false);

	const [createPost, { loading, error }] = useMutation(CONTACT_MUTATION,{
		onCompleted: (data) => {
			setSubmitSuccess(true);
			//console.log("Data from mutation", data)
		},
		onError: (error) => {
			setSubmitError(true);
			//console.error("Error creating a post", error)
		},
	  });

	const { register, handleSubmit, errors } = useForm();

	const onSubmit = (data) => {
		//console.log(data);
		setSubmitError(false);
		setSubmitSuccess(false);
		createPost({
			variables: {
				clientMutationId: "mecklikform",
				firstName: firstNameValue,
				lastName: lastNameValue,
				email: emailValue,
				message: messageValue,
			},
		});
	}
	return (
		<>
			<SEO title={page.seo.title} description={page.seo.metaDesc} />
			<Nav />
			<Box w="100%" maxW={600} mx="auto" mt={"100px"} px={"16px"}>
				<ParaGraph dangerouslySetInnerHTML={{ __html: page.content }} />

						<React.Fragment>
							<form
 								onSubmit={handleSubmit(onSubmit)}
							>
								<FormControl>
									<FormLabel htmlFor="firstName">
										Etunimi *
									</FormLabel>
									<Input
										id="firstName"
										onChange={(event) => {
											setFirstNameValue(
												event.target.value
											);
										}}
										name="firstName"
										ref={register({ required: true })}
										aria-invalid={
											errors.firstName ? "true" : "false"
										}
									/>

									<br />
									<br />

									<FormLabel htmlFor="lastName">
										Sukunimi *
									</FormLabel>
									<Input
										id="lastName"
										onChange={(event) => {
											setLastNameValue(
												event.target.value
											);
										}}
										name="lastName"
										ref={register({ required: true })}
										aria-invalid={
											errors.firstName ? "true" : "false"
										}
									/>

									<br />
									<br />

									<FormLabel htmlFor="email">
										Email *
									</FormLabel>

									<Input
										id="email"
										onChange={(event) => {
											setEmailValue(event.target.value);
										}}
										name="email"
										ref={register({ required: true })}
										aria-invalid={
											errors.email ? "true" : "false"
										}
									/>
									<br />
									<br />

									<FormLabel htmlFor="message">
										Viesti *
									</FormLabel>
									<Textarea
										id="message"
										name="message"
										onChange={(event) => {
											setMessageValue(event.target.value);
										}}
										ref={register({ required: true })}
										aria-invalid={
											errors.message ? "true" : "false"
										}
									></Textarea>
								</FormControl>
								<br />
								<br />

								<Button type="submit">Lähetä</Button>
							</form>

							<div style={{ padding: "20px" }}>
								{loading && <p>Loading...</p>}
								{submitError && (
									<p>
										Tapahtui virhe 😕 Yritä myöhemmin
										uudelleen...
									</p>
								)}
								{submitSuccess && <p>Kiitos viestistä 😊</p>
								}
							</div>
						</React.Fragment>
					
			</Box>
		</>
	);
};

export const query = graphql`
	query {
		page: wpPage(slug: { eq: "klik" }) {
			content
			title
			seo {
				metaDesc
				title
			}
		}
	}
`;

export default ContactPage;
